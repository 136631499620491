import { capitalize, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField as MuiTextField, useMediaQuery } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Error,
  FormDataConsumer,
  FormTab,
  FunctionField,
  Labeled,
  List,
  Loading,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  SimpleList,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  useInput,
  useNotify,
  useQuery,
  useRefresh,
  useTranslate
} from 'react-admin';
import { ShowDialog } from '../components/DialogForm';
import PaymentInput from '../components/PaymentInput';

const ParticipationFilters = [
  <ReferenceInput key={0} source="event_id" reference="events" filterToQuery={(searchText) => ({ name: searchText })}>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    key={1}
    source="person_id"
    reference="people"
    filterToQuery={(searchText) => ({
      fullname: searchText,
      has_account: true
    })}>
    <AutocompleteInput optionText="fullname" />
  </ReferenceInput>
];

const EventDataField = (props) => {
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'events',
    payload: { id: props.record.event_id }
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return data.data.map((val, i) => {
    if (val.type === 'bool') {
      return (
        <Labeled key={i} label={capitalize(val.name)}>
          <BooleanField source={'data.' + val.name} record={props.record} />
        </Labeled>
      );
    } else {
      return (
        <Labeled key={i} label={capitalize(val.name)}>
          <TextField source={'data.' + val.name} label={val.name} record={props.record} />
        </Labeled>
      );
    }
  });
};

EventDataField.propTypes = {
  record: PropTypes.any
};

const MultiButton = (props) => {
  if (props?.record?.transaction_id === null || props?.record?.transaction_id === undefined) {
    return <EditButton {...props} />;
  } else {
    return <ShowButton {...props} />;
  }
};

MultiButton.propTypes = {
  record: PropTypes.any
};

const Participation = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <>
      <List {...props} filters={ParticipationFilters} bulkActionButtons={false}>
        {isDesktop ? (
          <Datagrid>
            <TextField source="id" />
            <ReferenceField source="event_id" reference="events" link="show">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="person_id" reference="people" link="show">
              <FunctionField render={(r) => r.firstname + ' ' + r.lastname} />
            </ReferenceField>
            <ReferenceField source="transaction_id" reference="transactions" link="show">
              <TextField source="name" />
            </ReferenceField>
            <MultiButton />
          </Datagrid>
        ) : (
          <SimpleList
            secondaryText={(record) => (
              <ReferenceField record={record} source="event_id" reference="events" link={false}>
                <TextField source="name" />
              </ReferenceField>
            )}
            primaryText={(record) => (
              <ReferenceField record={record} source="person_id" reference="people" link={false}>
                <FunctionField render={(r) => r.firstname + ' ' + r.lastname} />
              </ReferenceField>
            )}
            tertiaryText={(record) => (record.transaction_id === null ? <CloseIcon /> : <CheckIcon />)}
            linkType="show"
          />
        )}
      </List>
      <ShowDialog>
        <SimpleShowLayout>
          <TextField source="id" />
          <ReferenceField source="event_id" reference="events" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="person_id" reference="people" link="show">
            <FunctionField render={(r) => r.firstname + ' ' + r.lastname} />
          </ReferenceField>
          <ReferenceField source="transaction_id" reference="transactions" link="show">
            <TextField source="name" />
          </ReferenceField>
          <EventDataField />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
};

const eventPrice = (event, person, data) => {
  const member = person.is_member;
  let price = member ? event.price_member : event.price;

  for (let dat of event.data) {
    if (dat.type === 'boolean') {
      if (data[dat.name] === true) {
        price += member ? dat.price_member : dat.price;
      }
    } else if (dat.type === 'numeric') {
      price += (member ? dat.price_member : dat.price) * data[dat.name];
    } else if (dat.type === 'select') {
      for (let val of dat.values) {
        if (data[dat.name] === val.name) {
          price += member ? val.price_member : val.price;
          break;
        }
      }
    }
  }

  return price;
};

const EventDataInput = ({ eventId, personId, priceChanged, ...props }) => {
  const {
    input: { onChange, value }
  } = useInput(props);
  console.log(value);

  const {
    data: event,
    loading: loading1,
    error: error1
  } = useQuery({
    type: 'getOne',
    resource: 'events',
    payload: { id: eventId }
  });

  const {
    data: person,
    loading: loading2,
    error: error2
  } = useQuery({
    type: 'getOne',
    resource: 'people',
    payload: { id: personId }
  });

  const onChangeWrapper = (name) => {
    return (e) => {
      let val = { ...value };
      val[name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      onChange(val);
    };
  };

  useEffect(() => {
    if (priceChanged !== undefined && event !== undefined && person !== undefined && value !== undefined) priceChanged(eventPrice(event, person, value));
  }, [event, person, value]); // eslint-disable-line

  useEffect(() => {
    let val = value;
    if (val === '') val = {};

    event?.data?.forEach((v) => {
      if (v.name in val) return;
      if (v.type === 'boolean') {
        val[v.name] = false;
      } else if (v.type === 'numeric') {
        val[v.name] = 0;
      } else if (v.type === 'select') {
        val[v.name] = v.values[0]?.name;
      } else {
        val[v.name] = '';
      }
    });

    onChange(val);
  }, [event]); // eslint-disable-line

  if (loading1 || loading2) return <Loading />;
  if (error1 || error2) return <Error />;
  if (!event || !person) return null;

  return event.data.map((structure, index) => {
    if (structure.type === 'boolean') {
      return (
        <FormControlLabel
          key={index}
          margin="normal"
          control={<Switch checked={value[structure.name] ?? false} onChange={onChangeWrapper(structure.name)} name={structure.name} />}
          label={capitalize(structure.name)}
        />
      );
    } else if (structure.type === 'numeric') {
      return (
        <FormControl key={index} variant="filled" margin="normal">
          <NumberInput label={capitalize(structure.name)} onChange={onChangeWrapper(structure.name)} name={structure.name} />
        </FormControl>
      );
    } else if (structure.type === 'select') {
      return (
        <FormControl key={index} variant="filled" margin="normal">
          <InputLabel id={'data-' + index}>{capitalize(structure.name)}</InputLabel>
          <Select labelId={'data-' + index} id={'data-select-' + index} value={value[structure.name] ?? ''} onChange={onChangeWrapper(structure.name)}>
            {structure.values.map((item, i) => {
              return (
                <MenuItem value={item.name} key={i}>
                  {capitalize(item.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <MuiTextField
          key={index}
          name={structure.name}
          label={capitalize(structure.name)}
          onChange={onChangeWrapper(structure.name)}
          value={value[structure.name] ?? ''}
          variant="filled"
          margin="normal"
          size="small"
        />
      );
    }
  });
};

EventDataInput.propTypes = {
  eventId: PropTypes.number,
  personId: PropTypes.number,
  priceChanged: PropTypes.func
};

const EditParticipation = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const [price, setPrice] = useState(0);

  return (
    <>
      <Edit
        {...props}
        onSuccess={() => {
          notify('ra.notification.created', 'info', { smart_count: 1 });
          refresh();
        }}>
        <TabbedForm syncWithLocation={false}>
          <FormTab label="Produits">
            <ReferenceField source="event_id" reference="events" link="show">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="person_id" reference="people" link="show">
              <FunctionField render={(r) => r.firstname + ' ' + r.lastname} />
            </ReferenceField>
            <ReferenceField source="transaction_id" reference="transactions" link="show">
              <TextField source="name" />
            </ReferenceField>
            <FormDataConsumer>
              {({ formData }) => {
                if (
                  formData.event_id === undefined ||
                  formData.person_id === undefined ||
                  (formData.transaction_id !== null && formData.transaction_id !== undefined)
                )
                  return null;
                return (
                  <EventDataInput
                    priceChanged={setPrice}
                    source="data"
                    key={formData.event_id + ' ' + formData.person_id}
                    eventId={formData.event_id}
                    personId={formData.person_id}
                  />
                );
              }}
            </FormDataConsumer>
            <MuiTextField
              value={Number(price).toLocaleString('fr-FR', {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                style: 'currency'
              })}
              disabled
              variant="filled"
              type="text"
              label={translate('inputs.multiproductcount.price')}
            />
          </FormTab>
          <FormTab label="Paiement">
            <FormDataConsumer>
              {({ formData }) => {
                if (formData.transaction_id !== null && formData.transaction_id !== undefined) return null;
                return <PaymentInput price={price} optional />;
              }}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
};

const AddParticipation = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const [price, setPrice] = useState(0);

  return (
    <>
      <Create
        {...props}
        onSuccess={() => {
          notify('ra.notification.created', 'info', { smart_count: 1 });
          refresh();
        }}>
        <TabbedForm syncWithLocation={false}>
          <FormTab label="Produits">
            <ReferenceInput source="event_id" reference="events" filterToQuery={(searchText) => ({ name: searchText })}>
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="person_id" reference="people" filterToQuery={(searchText) => ({ fullname: searchText })}>
              <AutocompleteInput optionText="fullname" />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData }) => {
                if (formData.event_id === undefined || formData.person_id === undefined) return null;
                return (
                  <EventDataInput
                    priceChanged={setPrice}
                    source="data"
                    key={formData.event_id + ' ' + formData.person_id}
                    eventId={formData.event_id}
                    personId={formData.person_id}
                  />
                );
              }}
            </FormDataConsumer>
            <MuiTextField
              value={Number(price).toLocaleString('fr-FR', {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                style: 'currency'
              })}
              disabled
              variant="filled"
              type="text"
              label={translate('inputs.multiproductcount.price')}
            />
          </FormTab>
          <FormTab label="Paiement">
            <PaymentInput price={price} optional />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
};

const participations = {
  list: Participation,
  create: AddParticipation,
  edit: EditParticipation,
  show: Participation
};

export default participations;
