import { TextField as MuiTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormDataConsumer, SelectInput, useTranslate } from 'react-admin';
import PersonalAccountSelector from '../components/PersonalAccountSelector';

const PaymentInput = ({ price, optional = false }) => {
  const translate = useTranslate();
  return (
    <>
      <MuiTextField
        value={Number(price).toLocaleString('fr-FR', {
          currency: 'EUR',
          currencyDisplay: 'symbol',
          style: 'currency'
        })}
        disabled
        variant="filled"
        type="text"
        label={translate('inputs.multiproductcount.price')}
      />
      <SelectInput
        source="payment"
        label="Moyen de paiement"
        allowEmpty={optional}
        choices={[
          { id: 'cash', name: 'Liquide (Caisse)' },
          { id: 'card', name: 'Carte Bancaire' },
          { id: 'account', name: 'Compte personel' }
        ]}
        initialValue={optional ? null : 'cash'}
      />
      <FormDataConsumer>{({ formData }) => formData.payment === 'account' && <PersonalAccountSelector source="token" label="Compte" />}</FormDataConsumer>
    </>
  );
};

PaymentInput.propTypes = {
  price: PropTypes.number,
  optional: PropTypes.bool,
  rest: PropTypes.any
};

export default PaymentInput;
