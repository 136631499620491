import { Button, CardActions, CircularProgress, Hidden, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useLogin, useNotify, useSafeSetState, useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { Login } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      paddingLeft: '1em',
      paddingRight: '1em'
    },
    input: {
      marginTop: '1em'
    },
    button: {
      width: '100%'
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    copying: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      textAlign: 'center',
      color: 'white'
    },
    link: {
      color: '#ade6fd'
    }
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

const MyLoginPage = (props) => {
  const inputRef = React.useRef();
  const [loading, setLoading] = useSafeSetState(false);
  const [qrcode, setQrcode] = useState('');
  const [twoFA, setTwoFA] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  useEffect(() => {
    let uuid = 'undefined';
    const interval = setInterval(
      (function intervalHandler() {
        axios.get('/csrf-cookie').then(() => {
          axios
            .get(`/fa/${uuid}`)
            .then((response) => {
              if (response?.data?.user_id !== null && response?.data?.accepted === true) {
                login(response?.data);
              }
            })
            .catch((error) => {
              if (error?.response?.status === 404) {
                return axios
                  .post('/fa')
                  .then((response) => {
                    console.log(response.data);
                    uuid = response.data.uuid;
                    setQrcode(response.data.svg);
                  })
                  .catch((error) => {
                    console.log(error);
                    notify(error?.response?.data?.message);
                  });
              }
            });
        });
        return intervalHandler;
      })(),
      5000
    );
    return () => clearInterval(interval);
  }, []);

  const submit = (values) => {
    setLoading(true);
    if (twoFA) {
      axios
        .post('/two-factor-challenge', {
          code: values.code,
          recovery_code: values.code
        })
        .then((response) => {
          setLoading(false);
          let data = response.data;
          if (data?.two_factor === true) {
            setTwoFA(true);
          } else {
            login(data, '/');
          }
        })
        .catch((error) => {
          setLoading(false);

          if (error?.response?.data?.message) {
            let message = '';
            if (error?.response?.data?.errors !== undefined) {
              for (let ms in error.response.data.errors) {
                for (let m of error.response.data.errors[ms]) {
                  message += m + '\n';
                }
              }
            } else {
              message = error?.response?.data?.message;
            }
            notify(message, 'warning');
          } else {
            notify(error?.message, 'warning');
          }
        });
    } else {
      axios.get('/csrf-cookie').then(() => {
        axios
          .post('/login', values)
          .then((response) => {
            setLoading(false);
            let data = response.data;
            if (data?.two_factor === true) {
              setTwoFA(true);
              inputRef.current.focus();
            } else {
              login(data, '/');
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error?.response?.data?.message) {
              let message = '';
              if (error?.response?.data?.errors !== undefined) {
                for (let ms in error.response.data.errors) {
                  for (let m of error.response.data.errors[ms]) {
                    message += m + '\n';
                  }
                }
              } else {
                message = error?.response?.data?.message;
              }
              notify(message, 'warning');
            } else {
              notify('Error', 'warning');
            }
          });
      });
    }
  };

  return (
    <>
      <Login>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) =>
            twoFA ? (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field inputRef={inputRef} id="code" name="code" component={Input} label={translate('ra.auth.2fa.verification_code')} disabled={loading} />
                  </div>
                </div>
                <CardActions>
                  <Button variant="contained" type="submit" color="secondary" disabled={loading} className={classes.button}>
                    {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                    {translate('ra.auth.2fa.login')}
                  </Button>
                </CardActions>
              </form>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <form onSubmit={handleSubmit} noValidate style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div className={classes.form} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className={classes.input}>
                      <Field autoFocus id="username" name="username" component={Input} label={translate('ra.auth.username')} disabled={loading} />
                    </div>
                    <div className={classes.input}>
                      <Field
                        id="password"
                        name="password"
                        component={Input}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                        autoComplete="current-password"
                        helperText={
                          <Link component={RouterLink} to="/forgot-password" className={classes.link}>
                            {translate('ra.auth.forgot')}
                          </Link>
                        }
                      />
                    </div>
                  </div>
                  <CardActions>
                    <Button variant="contained" type="submit" color="secondary" disabled={loading} className={classes.button}>
                      {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </CardActions>
                </form>
                <Hidden xsDown>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px' }}>
                    <div className={classes.form}>
                      <div style={{ background: 'white', borderRadius: '4px', padding: '8px' }}>
                        <div style={{ width: '192px', height: '192px' }} dangerouslySetInnerHTML={{ __html: qrcode }}></div>
                      </div>
                    </div>
                    <CardActions>
                      <div
                        style={{
                          padding: '6px 16px',
                          fontSize: '0.875rem',
                          minWidth: '64px',
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          lineHeight: 1.75
                        }}>
                        {translate('ra.auth.fa.title')}
                      </div>
                    </CardActions>
                  </div>
                </Hidden>
              </div>
            )
          }
        />
      </Login>
      <div className={classes.copying}>
        <Typography>
          Seb {process.env.REACT_APP_VERSION ?? 'DEV' /* eslint-disable-line */}
          <br />
          {translate('copying.bottommessage')}{' '}
          <Link to="/copying" component={RouterLink} className={classes.link}>
            GNU AGPLv3
          </Link>
          <br />
          <a className={classes.link} href="/doc/privacy-policy.pdf">
            Politique de Confidentialité
          </a>
        </Typography>
      </div>
    </>
  );
};

export default MyLoginPage;
