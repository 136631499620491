import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'react-admin';
import BreadCrumb from '../components/Breadcrumb';
import AppBar from './AppBar';
import { Menu } from './Menu';

const MyLayout = ({ children, ...props }) => (
  <Layout {...props} menu={Menu} appBar={AppBar}>
    <BreadCrumb />
    {children}
  </Layout>
);

MyLayout.propTypes = {
  children: PropTypes.node
};

export default MyLayout;
