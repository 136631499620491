import { Button, CardActions, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useNotify, useRedirect, useSafeSetState, useTranslate } from 'ra-core';
import React from 'react';
import { useAuthenticated } from 'react-admin';
import { Form } from 'react-final-form';
import { useParams } from 'react-router';
import BaseForm from './Form';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
      textAlign: 'center',
      width: '420px'
    },
    input: {
      marginTop: '1em'
    },
    button: {
      width: '100%'
    },
    icon: {
      marginRight: theme.spacing(1)
    }
  }),
  { name: 'RaLoginForm' }
);

const FastAuth = (props) => {
  console.log(props);
  useAuthenticated();
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  let { token } = useParams();
  const classes = useStyles(props);

  const submit = () => {
    setLoading(true);
    axios
      .put(`/fa/${token}`, { accept: true })
      .then(() => {
        notify('ra.auth.fa.authorized');
        redirect('/');
        setLoading(false);
      })
      .catch((error) => {
        notify(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <BaseForm>
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.form}>{translate('ra.auth.fa.authorize')}</div>
              <CardActions>
                <Button variant="contained" type="submit" color="secondary" disabled={loading} className={classes.button}>
                  {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                  {translate('ra.auth.fa.validate')}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    redirect('/');
                  }}
                  color="secondary"
                  disabled={loading}
                  className={classes.button}>
                  {translate('ra.auth.fa.refuse')}
                </Button>
              </CardActions>
            </form>
          );
        }}
      />
    </BaseForm>
  );
};

export default FastAuth;
