import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  SimpleForm,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';
import { JsonField, JsonInput } from 'react-admin-json-view';
import { DateTimeInput } from '../components/DateTimeInput';
import { CreateDialog, ShowDialog } from '../components/DialogForm';
import MoneyField from '../components/MoneyField';
import MoneyInput from '../components/MoneyInput';
import ResourceExportButton from '../components/ResourceExportButton';

const EventsFilters = [
  <TextInput key={0} source="name" />,
  <TextInput key={1} source="location" />,
  <ReferenceInput key={2} source="category_id" reference="transactions_categories" filterToQuery={(searchText) => ({ name: searchText })}>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
];

const Events = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <>
      <List {...props} filters={EventsFilters} bulkActionButtons={false}>
        {isDesktop ? (
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="location" />
            <DateField source="start" />
            <ShowButton />
            <ResourceExportButton file="export.csv" mime="text/csv" json={false} />
          </Datagrid>
        ) : (
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => record.location + ' - ' + new Date(record.start).toLocaleDateString()}
            tertiaryText={(record) => '#' + record.id}
            linkType="show"
          />
        )}
      </List>
      <CreateDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput source="name" />
          <TextInput source="location" />
          <DateTimeInput source="start" />
          <DateTimeInput source="end" />
          <DateTimeInput source="inscriptions_closed_at" />
          <JsonInput
            source="data"
            reactJsonOptions={{
              theme: 'monokai',
              displayObjectSize: false,
              displayDataTypes: false,
              enableClipboard: false,
              defaultValue: {
                name: '',
                type: ''
              }
            }}
            initialValue={[]}
          />
          <MoneyInput source="price" />
          <MoneyInput source="price_member" />
        </SimpleForm>
      </CreateDialog>
      <ShowDialog>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="location" />
          <DateField source="start" />
          <DateField source="end" />
          <DateField source="inscriptions_closed_at" />
          <MoneyField source="price" />
          <MoneyField source="price_member" />
          <ReferenceField source="category_id" reference="transactions_categories">
            <TextField source="name" />
          </ReferenceField>
          <JsonField
            source="data"
            addLabel
            reactJsonOptions={{
              theme: 'monokai',
              displayObjectSize: false,
              displayDataTypes: false,
              enableClipboard: false
            }}
          />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
};

const events = {
  list: Events,
  create: Events,
  show: Events
};

export default events;
