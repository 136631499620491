import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  SimpleForm,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin';
import DateField from '../components/DateField';
import DateInput from '../components/DateInput';
import { CreateDialog, EditDialog, ShowDialog } from '../components/DialogForm';
import MoneyField from '../components/MoneyField';
import MoneyInput from '../components/MoneyInput';
import { RecalculateButton } from '../components/RecalculateButton';

const AccountsFilters = [<TextInput key={0} source="name" />, <TextInput key={1} source="iban" />, <TextInput key={2} source="bic" />];

const AccountsListActions = () => (
  <TopToolbar>
    <FilterButton />
    <RecalculateButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const Accounts = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <>
      <List {...props} filters={AccountsFilters} actions={<AccountsListActions />}>
        {isDesktop ? (
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="iban" />
            <TextField source="bic" />
            <MoneyField noLabel={true} source="balance" />
            <EditButton />
          </Datagrid>
        ) : (
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) =>
              Number(record.balance).toLocaleString('fr-FR', {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                style: 'currency'
              })
            }
            tertiaryText={(record) => '#' + record.id}
          />
        )}
      </List>
      <CreateDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput source="name" />
          <TextInput source="iban" />
          <TextInput source="bic" />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput disabled source="id" />
          <TextInput source="name" />
          <TextInput source="iban" />
          <TextInput source="bic" />
          <MoneyInput disabled source="balance" />
          <DateInput disabled source="created_at" />
          <DateInput disabled source="updated_at" />
        </SimpleForm>
      </EditDialog>
      <ShowDialog>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="iban" />
          <TextField source="bic" />
          <MoneyField source="balance" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
};

const accounts = {
  list: Accounts,
  create: Accounts,
  edit: Accounts,
  show: Accounts
};

export default accounts;
