import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, useDataProvider, useNotify } from 'react-admin';

const download = (data, filename, type) => {
  let file = new Blob([data], { type: type });
  let a = document.createElement('a'),
    url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

const ResourceExportButton = ({ record, mime, file, json, ...rest }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onClick = () => {
    dataProvider.export(rest.resource, { id: record.id, json }).then((response) => {
      download(json ? JSON.stringify(response) : response.data, file, mime);
      notify('ra.notification.exported');
    });
  };

  return (
    <Button label="Exporter" {...rest} onClick={onClick}>
      <GetAppIcon />
    </Button>
  );
};

ResourceExportButton.propTypes = {
  record: PropTypes.any,
  mime: PropTypes.string,
  file: PropTypes.string,
  resource: PropTypes.string,
  json: PropTypes.bool
};

ResourceExportButton.defaulProps = {
  mime: 'application/json',
  file: 'export.json',
  json: true
};

export default ResourceExportButton;
