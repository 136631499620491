import * as React from 'react';
import { RouteWithoutLayout } from 'react-admin';
import { Route } from 'react-router-dom';
import personal_refills from '../resources/PersonalRefills';
import Profile from '../resources/Profile';
import ActivateAccount from './forms/ActivateAccount';
import ChangePassword from './forms/ChangePassword';
import Copying from './forms/Copying';
import FastAuth from './forms/FastAuth';
import FastAuthLogin from './forms/FastAuthLogin';
import ForgotPassword from './forms/ForgotPassword';
import ResetPassword from './forms/ResetPassword';
import TokenClear from './forms/TokenClear';
import TokenNew from './forms/TokenNew';
import TwoFactorDisable from './forms/TwoFactorDisable';
import TwoFactorEnable from './forms/TwoFactorEnable';

export const customRoutes = [
  <Route key={0} exact path="/profile" component={Profile} />,
  <RouteWithoutLayout key={1} exact path="/change-password" component={ChangePassword} />,
  <RouteWithoutLayout key={2} exact path="/forgot-password" component={ForgotPassword} />,
  <RouteWithoutLayout key={3} exact path="/reset-password/:token" component={ResetPassword} />,
  <RouteWithoutLayout key={4} exact path="/enable-account/:token" component={ActivateAccount} />,
  <RouteWithoutLayout key={5} exact path="/two-factor/enable" component={TwoFactorEnable} />,
  <RouteWithoutLayout key={6} exact path="/two-factor/disable" component={TwoFactorDisable} />,
  <RouteWithoutLayout key={7} exact path="/tokens/create" component={TokenNew} />,
  <RouteWithoutLayout key={8} exact path="/tokens/clear" component={TokenClear} />,
  <RouteWithoutLayout key={9} exact path="/copying" component={Copying} />,
  <Route key={10} exact path="/personal_accounts/refill" component={personal_refills.create} />,
  <RouteWithoutLayout key={11} exact path="/fa/:token" component={FastAuth} />,
  <RouteWithoutLayout key={12} exact path="/login/:token" component={FastAuthLogin} />
];
