import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import { Datagrid, EditButton, List, SimpleForm, SimpleList, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import DateField from '../components/DateField';
import DateInput from '../components/DateInput';
import { CreateDialog, EditDialog, ShowDialog } from '../components/DialogForm';
import ResourceExportButton from '../components/ResourceExportButton';

const PeopleFilters = [<TextInput key={0} source="firstname" />, <TextInput key={1} source="lastname" />, <TextInput key={2} source="discord_id" />];

const People = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <>
      <List {...props} filters={PeopleFilters}>
        {isDesktop ? (
          <Datagrid>
            <TextField source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="discord_id" />
            <EditButton />
            <ResourceExportButton />
          </Datagrid>
        ) : (
          <SimpleList
            primaryText={(record) => record.firstname + ' ' + record.lastname}
            secondaryText={(record) => record.discord_id}
            tertiaryText={(record) => '#' + record.id}
          />
        )}
      </List>
      <CreateDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput source="firstname" />
          <TextInput source="lastname" />
          <TextInput source="discord_id" />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput disabled source="id" />
          <TextInput source="firstname" />
          <TextInput source="lastname" />
          <TextInput source="discord_id" />
          <DateInput disabled source="created_at" />
          <DateInput disabled source="updated_at" />
        </SimpleForm>
      </EditDialog>
      <ShowDialog>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="firstname" />
          <TextField source="lastname" />
          <TextField source="discord_id" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
};

const people = {
  list: People,
  create: People,
  edit: People,
  show: People
};

export default people;
