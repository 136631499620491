import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useLogin, useNotify, useRedirect, useTranslate } from 'ra-core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import BaseForm from './Form';

const useStyles = makeStyles(
  () => ({
    form: {
      padding: '0 1em 1em 1em',
      textAlign: 'center',
      width: '420px'
    }
  }),
  { name: 'RaLoginForm' }
);

const FastAuthLogin = () => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  let { token } = useParams();
  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(
      (function intervalHandler() {
        axios.get('/csrf-cookie').then(() => {
          axios
            .get(`/fa/${token}`)
            .then((response) => {
              if (response?.data?.user_id !== null && response?.data?.accepted === true) {
                login(response?.data);
              }
            })
            .catch((error) => {
              if (error?.response?.status === 404) {
                notify('ra.auth.fa.expired', 'warning');
                redirect('/login');
              }
            });
        });
        return intervalHandler;
      })(),
      5000
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <BaseForm>
      <div className={classes.form}>{translate('ra.auth.fa.loading')}</div>
    </BaseForm>
  );
};

export default FastAuthLogin;
