import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import { Datagrid, EditButton, List, SimpleForm, SimpleList, SimpleShowLayout, TextField, TextInput } from 'react-admin';
import DateField from '../components/DateField';
import DateInput from '../components/DateInput';
import { CreateDialog, EditDialog, ShowDialog } from '../components/DialogForm';

const ProductsCategoriesFilters = [<TextInput key={0} source="name" />];

const ProductsCategories = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <>
      <List {...props} filters={ProductsCategoriesFilters}>
        {isDesktop ? (
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <EditButton />
          </Datagrid>
        ) : (
          <SimpleList primaryText={(record) => record.name} tertiaryText={(record) => '#' + record.id} linkType="edit" />
        )}
      </List>
      <CreateDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput source="name" />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props}>
        <SimpleForm redirect="list">
          <TextInput disabled source="id" />
          <TextInput source="name" />
          <DateInput disabled source="created_at" />
          <DateInput disabled source="updated_at" />
        </SimpleForm>
      </EditDialog>
      <ShowDialog>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </SimpleShowLayout>
      </ShowDialog>
    </>
  );
};

const products_categories = {
  list: ProductsCategories,
  create: ProductsCategories,
  edit: ProductsCategories,
  show: ProductsCategories
};

export default products_categories;
